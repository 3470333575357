import React from 'react';
import style from './Text.module.css';

export enum TextTheme {
  Standard = 'Standard',
  Big = 'Big',
  BoldBlue = 'BoldBlue',
}

interface TextProps {
  children: React.ReactNode;
  theme?: TextTheme;
  className?: string;
  mb?: number;
}

export default function Text(props: TextProps) {
  const { mb } = props;

  const getClassByTheme = (theme?: TextTheme) => {
    switch (theme) {
      case TextTheme.BoldBlue:
        return style.boldBlue;
      case TextTheme.Big:
        return style.big;
      case TextTheme.Standard:
      default:
        return style.standard;
    }
  };

  return (
    <div
      className={[
        style.root,
        getClassByTheme(props.theme),
        props.className,
      ].join(' ')}
      style={{ marginBottom: mb }}
    >
      {props.children}
    </div>
  );
}
