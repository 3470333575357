export enum LocalStorageItem {
  Token = 'token',
}

export class LocalStorage {
  static setToken(token: string): void {
    localStorage.setItem(LocalStorageItem.Token, token);
  }
  static getToken(): string | null {
    return localStorage.getItem(LocalStorageItem.Token);
  }
}
