import {
  SET_TOKEN,
  ActionTypes,
  START_CLASSIFY,
  CLASSIFY_SUCCEEDED,
  SET_CURRENT_STAGE,
  START_RECOGNIZE,
  RECOGNIZE_SUCCEEDED,
  SET_APP_ERROR,
  AppError,
  CLEAR_APP_ERROR,
  SET_PROGRESS,
  StartRecognizePayload,
  RESET_APP,
  RecognizeSucceededPayload,
  SetDocumentProgressPayload,
  SET_DOCUMENT_PROGRESS,
  SET_DOCUMENT_LOADING,
} from './types';
import { AppStage } from '../App';
import { DocumentInfo } from '../@types/api';

// TypeScript infers that this function is returning SendMessageAction
export function setToken(token: string): ActionTypes {
  return {
    type: SET_TOKEN,
    payload: token,
  };
}

export function setAppError(error: AppError): ActionTypes {
  return {
    type: SET_APP_ERROR,
    payload: error,
  };
}

export function clearAppError(): ActionTypes {
  return {
    type: CLEAR_APP_ERROR,
  };
}

export function setCurrentStage(stage: AppStage): ActionTypes {
  return {
    type: SET_CURRENT_STAGE,
    payload: stage,
  };
}

export function startClassify({
  file,
  preview,
}: {
  file: File[];
  preview: string;
}): ActionTypes {
  return {
    type: START_CLASSIFY,
    payload: { file, preview },
  };
}

export function startRecognize(payload: StartRecognizePayload): ActionTypes {
  return {
    type: START_RECOGNIZE,
    payload: payload,
  };
}

export function classifySucceeded(payload: {pages: DocumentInfo[], summary: any}): ActionTypes {
  return {
    type: CLASSIFY_SUCCEEDED,
    payload: payload,
  };
}

export function recognizeSucceeded(
  payload: {pages: DocumentInfo[], summary: any},
): ActionTypes {
  return {
    type: RECOGNIZE_SUCCEEDED,
    payload: payload,
  };
}

export function setProgress(payload: number): ActionTypes {
  return {
    type: SET_PROGRESS,
    payload: payload,
  };
}

export function setDocumentProgress(
  payload: SetDocumentProgressPayload,
): ActionTypes {
  return {
    type: SET_DOCUMENT_PROGRESS,
    payload: payload,
  };
}

export function setDocumentLoading(payload: {
  id: string;
  isLoading: boolean;
}): ActionTypes {
  return {
    type: SET_DOCUMENT_LOADING,
    payload,
  };
}

export function resetApp(): ActionTypes {
  return {
    type: RESET_APP,
  };
}
