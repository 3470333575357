import React from 'react'

interface HandlLogoProps {
  light?: boolean
}

export default function HandlLogo(props: HandlLogoProps) {
  return (
    <svg
      width="83"
      height="22"
      viewBox="0 0 83 22"
      fill={props.light ? '#fff' : '#000'}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M82.2195 21.691H78.0059V0H82.2195V21.691Z" />
      <path d="M68.1605 6.74678C68.9161 7.11159 69.4514 7.46352 69.7666 7.80687L70.2372 8.36481V0H74.4379V21.691H70.2372V19.8326C70.1983 19.8927 70.1336 19.97 70.0472 20.0687C69.9609 20.1631 69.7795 20.3348 69.4989 20.5837C69.2183 20.8326 68.9161 21.0515 68.5923 21.2489C68.2685 21.4464 67.8454 21.618 67.3316 21.7725C66.8135 21.9227 66.2782 22 65.717 22C63.7396 22 62.0213 21.2361 60.5664 19.7082C59.1115 18.1803 58.3818 16.3133 58.3818 14.103C58.3818 11.8927 59.1115 10.0258 60.5664 8.49785C62.0213 6.96995 63.7396 6.20601 65.717 6.20601C66.589 6.20601 67.405 6.38627 68.1605 6.74678ZM69.1406 17.1116C69.8702 16.3262 70.2372 15.3219 70.2372 14.103C70.2372 12.8841 69.8702 11.8798 69.1406 11.0987C68.4109 10.3176 67.5302 9.92703 66.4941 9.92703C65.4579 9.92703 64.5772 10.3176 63.8475 11.0987C63.1179 11.8798 62.751 12.8798 62.751 14.103C62.751 15.3219 63.1179 16.3262 63.8475 17.1116C64.5772 17.897 65.4622 18.2919 66.4941 18.2919C67.5302 18.2876 68.4109 17.897 69.1406 17.1116Z" />
      <path d="M45.6216 8.37013C45.7425 8.20704 45.9152 8.0096 46.1397 7.77355C46.3642 7.5375 46.8521 7.21562 47.6076 6.81218C48.3631 6.40875 49.1575 6.20703 49.9908 6.20703C51.735 6.20703 53.1597 6.80789 54.2606 8.00532C55.3615 9.20703 55.9098 10.8251 55.9098 12.8594V21.692H51.709V13.3272C51.709 12.4002 51.4327 11.6491 50.8801 11.0826C50.3275 10.5117 49.6152 10.2285 48.7431 10.2285C47.7889 10.2285 47.0334 10.5375 46.4678 11.1598C45.9023 11.7821 45.6216 12.6577 45.6216 13.7993V21.6963H41.4209V6.51175H45.6216V8.37013Z" />
      <path d="M33.6522 6.50491V8.36329L33.1816 7.80535C32.8665 7.46201 32.3311 7.11008 31.5756 6.74527C30.8201 6.38046 30.0041 6.2002 29.132 6.2002C27.1547 6.2002 25.4364 6.96414 23.9814 8.49204C22.5265 10.0199 21.7969 11.8869 21.7969 14.0972C21.7969 16.3075 22.5265 18.1744 23.9814 19.7023C25.4364 21.2302 27.1547 21.9942 29.132 21.9942C29.6889 21.9942 30.2286 21.9169 30.7467 21.7667C31.2647 21.6165 31.6835 21.4405 32.0073 21.2431C32.3311 21.0457 32.6333 20.8225 32.914 20.5779C33.1946 20.329 33.3759 20.1573 33.4623 20.0629C33.5486 19.9684 33.6134 19.8912 33.6522 19.8268V21.6852H37.853V6.50063H33.6522V6.50491ZM32.5599 17.1101C31.8303 17.8955 30.9453 18.2903 29.9134 18.2903C28.8773 18.2903 27.9965 17.8955 27.2669 17.1101C26.5373 16.3247 26.1703 15.3204 26.1703 14.1015C26.1703 12.8826 26.5373 11.8783 27.2669 11.0972C27.9965 10.3161 28.8816 9.92551 29.9134 9.92551C30.9496 9.92551 31.8303 10.3161 32.5599 11.0972C33.2896 11.8783 33.6565 12.8783 33.6565 14.1015C33.6522 15.3204 33.2896 16.3204 32.5599 17.1101Z" />
      <path d="M18.6983 21.691H14.0269V12.8584H4.68429V21.691H0V0H4.68429V8.6824H14.0269V0H18.6983V21.691Z" />
    </svg>
  )
}
