import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { AppState } from './types';
import { appReducer } from './reducers';
import createSagaMiddleware from 'redux-saga';
import AppSaga from '../sagas';

export default function configureStore(preloadedState?: AppState) {
  const sagaMiddleware = createSagaMiddleware();

  const middlewares = [sagaMiddleware];

  const middlewareEnhancer = applyMiddleware(...middlewares);

  const enhancers = [middlewareEnhancer];
  const composedEnhancers = composeWithDevTools(...enhancers);

  const store = createStore(appReducer, preloadedState, composedEnhancers);
  sagaMiddleware.run(AppSaga);

  return store;
}
