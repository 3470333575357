import React from 'react';
import style from './Container.module.css';

export interface ContainerProps {
  children: React.ReactNode;
  noMaxWidth?: boolean;
}

const Container = (props: ContainerProps) => {
  const classes = [style.root];

  if (props.noMaxWidth) {
    classes.push(style.noMaxWidth);
  }

  return <div className={classes.join(' ')}>{props.children}</div>;
};

export default Container;
