import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputBase from '@material-ui/core/InputBase';
import style from './Select.module.css';

const BootstrapInput = withStyles(theme => ({
  input: {
    borderRadius: 24,
    position: 'relative',
    backgroundColor: '#EEEEEE',
    fontSize: 16,
    padding: '14px 24px 14px',
    width: '100%',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&:focus': {
      borderRadius: 24,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
}))(InputBase);

export interface SelectItem {
  text: string;
  value: string;
}

export interface DbrainSelectProps {
  selected: string;
  items: SelectItem[];
  onChange: (value: string) => void;
}

export default function DbrainSelect(props: DbrainSelectProps) {
  const handleChange = (event: any) => {
    props.onChange(event.target.value);
  };

  return (
    <div>
      <FormControl className={[style.formControl].join(' ')}>
        <Select
          value={props.selected}
          onChange={handleChange}
          placeholder="Тип документа"
          input={<BootstrapInput />}
        >
          {props.items.map((item, index) => (
            <MenuItem value={item.value} key={index}>
              {item.text}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
