import React from 'react'
import style from './Bbox.module.css'

export interface BboxProps {
  isActive?: boolean
  coordinates: number[] // [x1,y1,x2,y2]
  containerClientHeight: number
  containerClientWidth: number
  imgHeight: number
  imgWidth: number
}

const Bbox = (props: BboxProps) => {
  const { containerClientHeight, containerClientWidth, imgHeight, imgWidth } = props
  const [rx1, ry1, rx2, ry2, rx3, ry3] = props.coordinates

  const width = Math.abs(
    containerClientWidth * rx2 - containerClientWidth * rx1,
  ) / imgWidth
  const height = Math.abs(
    containerClientHeight * ry1 - containerClientHeight * ry3,
  ) / imgHeight

  const offsetX = Math.round(containerClientWidth * rx1 / imgWidth)
  const offsetY = Math.round(containerClientHeight * ry1 / imgHeight)

  return (
    <div
      className={[style.root, ...(props.isActive ? [style.active] : [])].join(
        ' ',
      )}
      style={{
        width,
        height,
        top: offsetY,
        left: offsetX,
      }}
    ></div>
  )
}

export default Bbox
