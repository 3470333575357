import React from 'react'
import UploadStage from './components/UploadStage/UploadStage'
import UploadingStage from './components/UploadingStage/UploadingStage'
import RecognizingStage from './components/ClassifyingStage/ClassifyingStage'
import RecognizeStage from './components/RecognizeStage/RecognizeStage'
import { useSelector, useDispatch } from 'react-redux'
import { AppState, AppError, StartRecognizePayload } from './store/types'
import {
  setToken,
  startClassify,
  startRecognize,
  clearAppError,
  resetApp,
} from './store/actions'
import AppErrorScreen from './components/AppErrorScreen/AppErrorScreen'
import Layout from './components/Layout/Layout'
import { LocalStorage } from './utils/localStorage'
import { i18nInit } from './utils/i18n'
import { useTranslation } from 'react-i18next'
import { getAppLang } from './utils/utils'

export enum AppStage {
  Upload = 'Upload',
  Uploading = 'Uploading',
  Recognize = 'Recognize',
  Classifying = 'Classifying',
}

i18nInit(getAppLang())

export default function App() {
  // for testing
  // useEffect(() => {
  //   dispatch(startClassify({ file: [], preview: '' }))
  // }, [])

  const dispatch = useDispatch()
  const { t } = useTranslation()

  const token = useSelector((state: AppState) => state.token)
  const documents = useSelector((state: AppState) => state.documents)
  const appError = useSelector((state: AppState) => state.appError)
  const tokenError = useSelector((state: AppState) => state.tokenError)
  const currentStage = useSelector((state: AppState) => state.currentStage)

  const handleDrop = async (acceptedFiles: File[], preview: string) => {
    dispatch(startClassify({ file: acceptedFiles, preview }))
  }

  const handleRecognize = async (payload: StartRecognizePayload) => {
    dispatch(startRecognize(payload))
  }

  const handleReset = () => dispatch(resetApp())

  const renderCurrentStage = (stage: AppStage) => {
    switch (stage) {
      case AppStage.Uploading:
        return <UploadingStage />
      case AppStage.Classifying:
        return <RecognizingStage />
      case AppStage.Recognize:
        return (
          <RecognizeStage
            documents={documents}
            onReset={handleReset}
            onRecognize={handleRecognize}
            // {...{ documents: mockDocumentInfos }}
          />
        )
      case AppStage.Upload:
      default:
        return (
          <UploadStage
            onDrop={handleDrop}
            tokenError={tokenError}
            token={token}
            onTokenChange={(token: string) => {
              dispatch(setToken(token))
              LocalStorage.setToken(token)
            }}
          />
        )
    }
  }

  const handleClearAppError = () => dispatch(clearAppError())

  const renderAppError = (appError: AppError) => {
    switch (appError) {
      case AppError.Forbidden:
        return (
          <AppErrorScreen
            title={t('You entered an invalid token')}
            subtitle={t('Please try again or contact the Handl team')}
            onButtonClick={handleClearAppError}
          />
        )
      case AppError.InternalServerError:
        return (
          <AppErrorScreen
            title={t('Error 500')}
            subtitle={t(
              'Error 500. Please try again or contact the Handl team',
            )}
            onButtonClick={handleClearAppError}
          />
        )
      case AppError.ServiceUnavailable:
        return (
          <AppErrorScreen
            title={t('Error 503')}
            subtitle={t(
              'Error 503. Please try again or contact the Handl team',
            )}
            onButtonClick={handleClearAppError}
          />
        )
      case AppError.NullResponse:
        return (
          <AppErrorScreen
            title={t('No fields found in the document')}
            subtitle={t(
              'Please choose a different type of a document. If the document type is correct, please contact the Handl team',
            )}
            onButtonClick={handleClearAppError}
          />
        )
      default:
        return (
          <AppErrorScreen
            title={t('Unknown error')}
            subtitle={t('Please contact us')}
            onButtonClick={handleClearAppError}
          />
        )
    }
  }

  return (
    <Layout onReset={handleReset} stage={currentStage}>
      {appError ? renderAppError(appError) : renderCurrentStage(currentStage)}
    </Layout>
  )
}
