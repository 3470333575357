import React from 'react'

const DbrainLogo = () => {
    return (
        <svg
            width="78"
            height="18"
            viewBox="0 0 78 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M60.7632 3.77186C61.3866 3.77186 61.8808 3.59695 62.2382 3.25475C62.5955 2.91254 62.778 2.45627 62.7856 1.88593C62.7856 1.31559 62.6031 0.859312 62.2458 0.517108C61.8884 0.174903 61.3942 0 60.7708 0C60.2006 0 59.7444 0.174903 59.4023 0.517108C59.0601 0.859312 58.8853 1.31559 58.8853 1.88593C58.8853 2.45627 59.0525 2.91254 59.3947 3.25475C59.7292 3.59695 60.193 3.77186 60.7632 3.77186Z"
                fill="#FAFAFA"
            />
            <path
                d="M59.1209 17.7566H62.5271V5.40684H59.1209V17.7566Z"
                fill="#FAFAFA"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.55379 0.121582C9.116 0.121582 11.2677 0.97329 13.0087 2.67671C14.7498 4.38013 15.6166 6.47138 15.6166 8.93525C15.6166 11.4067 14.7498 13.4904 13.0087 15.1938C11.2677 16.8972 9.116 17.7489 6.55379 17.7489H0V0.121582H6.55379ZM3.7863 3.53602V14.3649H6.55379C8.01357 14.3649 9.26046 13.8402 10.2945 12.7908C11.3285 11.7413 11.8455 10.4638 11.8455 8.95046C11.8455 7.43716 11.3437 6.15959 10.3325 5.11017C9.32129 4.06074 8.06679 3.53602 6.5614 3.53602H3.7863Z"
                fill="#FAFAFA"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M29.7432 7.02652C30.9141 8.26606 31.4995 9.78696 31.4995 11.5816C31.4995 13.3763 30.9141 14.8972 29.7356 16.1368C28.5572 17.3763 27.1734 17.9999 25.5844 17.9999C24.8773 17.9999 24.2159 17.8554 23.6076 17.5664C22.9918 17.2774 22.5584 16.9885 22.3075 16.6995L21.935 16.2432V17.7565H18.5288V0.121584H21.9426V6.92005C21.973 6.87442 22.0262 6.80598 22.087 6.73754C22.1554 6.6615 22.2999 6.52461 22.5204 6.3269C22.7409 6.12918 22.9918 5.94667 23.2503 5.78697C23.5088 5.62728 23.8585 5.48279 24.2767 5.35351C24.7025 5.22424 25.1358 5.1634 25.5844 5.1634C27.1886 5.1634 28.5724 5.77937 29.7432 7.02652ZM27.0974 14.0303C27.6828 13.3915 27.9794 12.5778 27.9794 11.5816C27.9794 10.593 27.6828 9.77936 27.0974 9.14058C26.512 8.5018 25.7973 8.19001 24.961 8.19001C24.117 8.19001 23.4099 8.5094 22.8245 9.14058C22.2391 9.77936 21.9426 10.593 21.9426 11.5816C21.9426 12.5702 22.2391 13.3915 22.8245 14.0303C23.4099 14.6691 24.1246 14.9885 24.961 14.9885C25.7973 14.9885 26.512 14.6691 27.0974 14.0303Z"
                fill="#FAFAFA"
            />
            <path
                d="M41.4591 8.68948L41.4667 8.69199H41.4591V8.68948Z"
                fill="#FAFAFA"
            />
            <path
                d="M41.4591 8.68948C41.2082 8.60743 40.8306 8.56264 40.3339 8.55511C39.4367 8.55511 38.7296 8.83648 38.2126 9.40682C37.6956 9.96956 37.4295 10.7832 37.4295 11.8403V17.7566H34.0386V5.40683H37.4295V6.92013C37.5284 6.80607 37.6652 6.65398 37.8477 6.47147C38.0302 6.28896 38.4255 6.04561 39.0338 5.74143C39.642 5.43725 40.2882 5.28516 40.9573 5.28516H41.4591V8.68948Z"
                fill="#FAFAFA"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M50.5069 5.60464C51.1151 5.90122 51.5485 6.19019 51.807 6.46395L52.1871 6.92022V5.44494H55.5781V17.7567H52.1871V16.2434C52.1567 16.289 52.1035 16.3575 52.0351 16.4335C51.9667 16.5096 51.8146 16.6541 51.5941 16.8518C51.366 17.0495 51.1227 17.232 50.8642 17.3917C50.6057 17.5514 50.2636 17.6959 49.8454 17.8175C49.4272 17.9392 48.9939 18.0001 48.5453 18.0001C46.9487 18.0001 45.5573 17.3765 44.3865 16.1369C43.208 14.8974 42.6226 13.3765 42.6226 11.5818C42.6226 9.78714 43.208 8.26623 44.3865 7.02669C45.5649 5.78715 46.9487 5.16358 48.5453 5.16358C49.2448 5.16358 49.8986 5.30806 50.5069 5.60464ZM51.3052 14.0305C51.8982 13.3917 52.1871 12.578 52.1871 11.5818C52.1871 10.5932 51.8906 9.77954 51.3052 9.14075C50.7122 8.50197 50.0051 8.19019 49.1687 8.19019C48.3324 8.19019 47.6177 8.50958 47.0323 9.14075C46.4393 9.77954 46.1504 10.5932 46.1504 11.5818C46.1504 12.5704 46.4469 13.3917 47.0323 14.0305C47.6253 14.6693 48.3324 14.9887 49.1687 14.9887C49.9975 14.9887 50.7122 14.6693 51.3052 14.0305Z"
                fill="#FAFAFA"
            />
            <path
                d="M69.3392 6.92026C69.438 6.79098 69.5749 6.62369 69.7573 6.43357C69.9398 6.24346 70.3352 5.9773 70.9434 5.6503C71.5517 5.3233 72.1979 5.156 72.867 5.156C74.2735 5.156 75.4292 5.6427 76.3187 6.61608C77.2083 7.58946 77.6493 8.90505 77.6493 10.5628V17.7415H74.2583V10.9431C74.2583 10.1902 74.0378 9.58186 73.5893 9.11798C73.1407 8.6541 72.5705 8.42596 71.8634 8.42596C71.0955 8.42596 70.4796 8.67691 70.0235 9.17881C69.5673 9.68071 69.3392 10.3955 69.3392 11.3233V17.7415H65.9482V5.40696H69.3392V6.92026Z"
                fill="#FAFAFA"
            />
        </svg>
    )
}

export default DbrainLogo
