import React from 'react';
import style from './Button.module.css';

export enum ButtonSize {
  L = 'L',
  M = 'M',
  S = 'S',
}
export interface ButtonProps {
  children: React.ReactNode;
  theme?: ButtonTheme;
  size?: ButtonSize;
  disabled?: boolean;
  onClick?: () => void;
  onMouseOver?: () => void;
  onMouseLeave?: () => void;
  on?: () => void;
}

export enum ButtonTheme {
  White = 'Black',
  Black = 'White',
  Green = 'Green',
  BlackOnWhite = 'BlackOnWhite',
  Blue = 'Blue',
}

const getClassNameBySize = (size?: ButtonSize): string => {
  switch (size) {
    case ButtonSize.S:
      return style.sizeS;
    case ButtonSize.M:
    default:
      return style.sizeM;
  }
};

const getClassNameByTheme = (theme?: ButtonTheme): string => {
  switch (theme) {
    case ButtonTheme.Black:
      return style.black;
    case ButtonTheme.Blue:
      return style.blue;
    case ButtonTheme.BlackOnWhite:
      return style.blackOnWhite;
    case ButtonTheme.Green:
      return style.green;
    case ButtonTheme.White:
    default:
      return style.white;
  }
};

export default function Button(props: ButtonProps) {
  const { theme, size, disabled, onClick, onMouseOver, onMouseLeave } = props;
  const classNames = [
    style.root,
    getClassNameBySize(size),
    getClassNameByTheme(theme),
  ];

  if (disabled) classNames.push(style.disabled);

  const handleOnClick = () => {
    if (disabled) return;

    if (onClick) onClick();
  };

  return (
    <button
      className={classNames.join(' ')}
      onClick={handleOnClick}
      onMouseEnter={onMouseOver}
      onMouseLeave={onMouseLeave}
    >
      {props.children}
    </button>
  );
}
