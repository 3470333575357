import React from 'react';
import style from './LoadingBar.module.css';

export interface LoadingBarProps {
  progress?: number;
}

export default function LoadingBar(props: LoadingBarProps) {
  return (
    <div className={style.root}>
      <div
        className={style.loading}
        style={{ transform: `translateX(-${100 - (props.progress || 0)}%)` }}
      ></div>
    </div>
  );
}
