import React, { useState } from 'react'
import TextareaAutosize from '@material-ui/core/TextareaAutosize'
import style from './EditableTextArea.module.css'
import { IconButton } from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'
import DoneIcon from '@material-ui/icons/Done'

export interface EditableTextAreaProps {
  value: string
  onMouseEnter: () => void
  onMouseLeave: () => void
  onSave: (value: string) => void
}

export default function EditableTextArea(props: EditableTextAreaProps) {
  const [value, setValue] = useState<string>(props.value)
  const [isEditing, setIsEditing] = useState<boolean>(false)

  const handleSave = () => {
    console.log('here')
    setIsEditing(false)
    props.onSave(value)
  }

  const renderEditControls = () => {
    return isEditing ? (
      <IconButton aria-label="done" onClick={handleSave} className={style.icon}>
        <DoneIcon />
      </IconButton>
    ) : (
      <IconButton
        aria-label="edit"
        onClick={() => setIsEditing(true)}
        className={style.icon}
      >
        <EditIcon />
      </IconButton>
    )
  }

  const handleMouseEnter = () => {
    if (isEditing) return
    props.onMouseEnter()
  }

  const handleMouseLeave = () => {
    if (isEditing) return
    props.onMouseEnter()
  }

  return (
    <div
      className={style.root}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <TextareaAutosize
        value={value}
        className={style.root}
        disabled={!isEditing}
        onChange={(e) => setValue(e.target.value)}
      />
      {/* <input
        type="text"
        value={value}
        className={style.root}
        disabled={!isEditing}
        onChange={(e) => setValue(e.target.value)}
      /> */}
      <div className={style.controls}>{renderEditControls()}</div>
    </div>
  )
}
