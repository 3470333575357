import React from 'react';
import style from './CheckBoxChecked.module.css';

export default function CheckBoxChecked() {
  return (
    <div className={style.root}>
      <div className={style.box}></div>
    </div>
  );
}
