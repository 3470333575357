import { call, put, takeLatest, takeEvery } from 'redux-saga/effects'
import { Api } from '../api/api'
import {
  START_CLASSIFY,
  StartClassifyAction,
  StartRecognizeAction,
  START_RECOGNIZE,
  AppError,
} from '../store/types'
import {
  setCurrentStage,
  recognizeSucceeded,
  setAppError,
  setDocumentProgress,
  setDocumentLoading,
  setProgress,
} from '../store/actions'
import { AppStage } from '../App'
import { AxiosError, AxiosResponse } from 'axios'
import { wait } from '../utils/utils'
import {v4 as uuidv4} from 'uuid'
import {DocumentInfo} from "../@types/api";

const transformHttpErrorToAppError = (e: AxiosError) => {
  const { response } = e
  if (response?.status === 403) {
    return AppError.Forbidden
  }

  if (response?.status === 500) {
    return AppError.InternalServerError
  }

  if (response?.status === 503) {
    return AppError.ServiceUnavailable
  }

  return AppError.Other
}

function readFile(file: File){
  return new Promise((resolve, reject) => {
    var fr = new FileReader();
    fr.onload = () => {
      resolve(fr.result )
    };
    fr.onerror = reject;
    fr.readAsDataURL(file);
  });
}

function* classify(action: StartClassifyAction) {
  try {
    yield put(setCurrentStage(AppStage.Uploading))
    yield put(setProgress(20))
    yield put(setCurrentStage(AppStage.Classifying))
    yield put(setProgress(40))
    const response: AxiosResponse = yield call(
      Api.classify,
      action.payload.file,
    )
    // for testing
    // const response = recognizeResponseMock

    const pages: DocumentInfo[] = response.data.pages?.map((item: DocumentInfo) => {
      const id = uuidv4();
      return {...item, id};
    });

    if (!pages || pages.length === 0) {
      yield put(setAppError(AppError.NullResponse))
      return
    }

    yield put(
        recognizeSucceeded({pages, summary: response.data.summary}),
    )
    yield put(setProgress(100))
    yield put(setCurrentStage(AppStage.Recognize))
    yield put(setProgress(0))
  } catch (e) {
    console.log('function*classify -> e', e)
    yield put(setAppError(transformHttpErrorToAppError(e)))
  }
}

function* recognize(action: StartRecognizeAction) {
  try {
    const {
      payload: {
        document: { id },
      },
    } = action
    yield put(
      setDocumentLoading({
        id,
        isLoading: true,
      }),
    )
    yield put(
      setDocumentProgress({
        id,
        progress: 20,
      }),
    )
    yield wait(400)
    yield put(
      setDocumentProgress({
        id,
        progress: 30,
      }),
    )

    const response = yield call(Api.recognize, action.payload)

    yield put(
      setDocumentProgress({
        id,
        progress: 70,
      }),
    )

    const pages: DocumentInfo[] = response.data.pages?.map((item: DocumentInfo) => {
      const id = uuidv4();
      return {...item, id};
    });

    if (!pages || pages.length === 0) {
      yield put(setAppError(AppError.NullResponse))
      return
    }

    yield put(
      setDocumentProgress({
        id,
        progress: 100,
      }),
    )
    yield wait(1000)
    yield put(
      recognizeSucceeded({pages, summary: response.data.summary}),
    )
    yield put(
      setDocumentLoading({
        id: action.payload.document.id,
        isLoading: false,
      }),
    )
  } catch (e) {
    yield put(setAppError(transformHttpErrorToAppError(e)))
  }
}

function* AppSaga() {
  yield takeLatest(START_CLASSIFY, classify)
  yield takeEvery(START_RECOGNIZE, recognize)
}

export default AppSaga
