import React from 'react';
import style from './UploadStage.module.css';
import MultifileUpload from '../MultifileUpload/MultifileUpload';
import Container from '../Container/Container';

export interface UploadStageProps {
  token: string;
  tokenError?: string;
  onTokenChange: (value: string) => void;
  onDrop: (acceptedFiles: File[], preview: string) => void;
}

const UploadStage = (props: UploadStageProps) => {
  return (
    <div>
      <div className={style.tokenSection}>
        <div className={style.tokenForm}>
          <Container>
          </Container>
        </div>
      </div>
      <Container>
        <MultifileUpload onDrop={props.onDrop} />
      </Container>
    </div>
  );
};

export default UploadStage;
