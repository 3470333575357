export enum Confidence {
  Low = 'Low',
  Medium = 'Medium',
  High = 'High',
}

export type Matrix<V> = {
  [key: string]: {
    [key: string]: V;
  };
};

export enum DocType {
  BankCard = 'bank_card',
  DriverLicense1999PaperBack = 'driver_license_1999_paper_back',
  DriverLicense1999PaperFront = 'driver_license_1999_paper_front',
  DriverLicense1999PlasticBack = 'driver_license_1999_plastic_back',
  DriverLicense1999PlasticFront = 'driver_license_1999_plastic_front',
  DriverLicense2011Back = 'driver_license_2011_back',
  DriverLicense2011Front = 'driver_license_2011_front',
  DriverLicense2014Back = 'driver_license_2014_back',
  DriverLicenseJapan = 'driver_license_japan',
  GlobalPassport = 'global_passport',
  InnOrganisation = 'inn_organisation',
  InnPerson = 'inn_person',
  InsurancePlastic = 'insurance_plastic',
  KgzPassportMain = 'kgz_passport_main',
  KgzPassportPlasticBlue = 'kgz_passport_plastic_blue',
  KgzPassportPlasticRed = 'kgz_passport_plastic_red',
  MigrationCard = 'migration_card',
  MilitaryId = 'military_id',
  MtsActs = 'mts_acts',
  MtsRfa = 'mts_rfa',
  Ndfl2 = 'ndfl2',
  NotDocument = 'not_document',
  Ogrn = 'ogrn',
  Ogrnip = 'ogrnip',
  Other = 'other',
  PassportBlankPage = 'passport_blank_page',
  PassportChildren = 'passport_children',
  PassportLastRf = 'passport_last_rf',
  PassportMain = 'passport_main',
  PassportMainHandwritten = 'passport_main_handwritten',
  PassportMarriage = 'passport_marriage',
  PassportMilitary = 'passport_military',
  PassportPreviousDocs = 'passport_previous_docs',
  PassportRegistration = 'passport_registration',
  PassportZeroPage = 'passport_zero_page',
  PtsBack = 'pts_back',
  PtsFront = 'pts_front',
  RegistrationCertificate = 'registration_certificate',
  RusWorkPatent = 'rus_work_patent',
  SnilsBack = 'snils_back',
  SnilsFront = 'snils_front',
  TjkPassportMain = 'tjk_passport_main',
  UzbPassportMain = 'uzb_passport_main',
  VehicleRegistrationCertificateBack = 'vehicle_registration_certificate_back',
  VehicleRegistrationCertificateFront = 'vehicle_registration_certificate_front',
}

export type HashMap<T> = {
  [key: string]: T;
};
