import style from './Heading.module.css';
import { CSSProperties } from 'react';
import React from 'react';

export interface HeadingProps {
    weight: number;
    children: React.ReactNode;
    center?: boolean;
    className?: string;
    noMbMobile?: boolean;
    style?: CSSProperties;
}

const getClassNameByWeight = (weight: number): string => {
    const classNames = [style.h1, style.h2, style.h3, style.h4, style.h5];
    return classNames[weight - 1] || classNames[0];
};

export default function Heading(props: HeadingProps) {
    const { center, noMbMobile, className } = props;
    let classNames: string[] = [style.title, getClassNameByWeight(props.weight)];

    if (center) {
        classNames.push(style.center);
    }

    if (noMbMobile) {
        classNames.push(style.noMbMobile);
    }

    if (className) {
        classNames.push(className);
    }

    return (
        <div className={classNames.join(' ')} style={props.style}>
            {props.children}
        </div>
    );
}
