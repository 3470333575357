import React from 'react';
import style from './AppErrorScreen.module.css';
import Heading from '../Heading/Heading';
import Button, { ButtonTheme } from '../Button/Button';
import Container from '../Container/Container';
import Text from '../Text/Text';
import { useTranslation } from 'react-i18next';

export interface AppErrorProps {
  title: string;
  subtitle: string;
  onButtonClick: () => void;
}

const AppErrorScreen = (props: AppErrorProps) => {
  const { t } = useTranslation();
  return (
    <div className={style.root}>
      <Container>
        <Heading weight={1}>{props.title}</Heading>
        <Text className={style.subtitle}>{props.subtitle}</Text>
        <Button theme={ButtonTheme.BlackOnWhite} onClick={props.onButtonClick}>
          {t('Go back')}
        </Button>
      </Container>
    </div>
  );
};

export default AppErrorScreen;
