import React from 'react';
import DbrainLogo from '../DbrainLogo/DbrainLogo';
import style from './Header.module.css';
import { AppStage } from '../../App';
import { AppLanguage } from '../../store/types';
import HandlLogo from '../HandlLogo/HandlLogo';
import { useTranslation } from 'react-i18next';

export interface HeaderProps {
  progress: number;
  stage: AppStage;
  onReset: () => void;
}

const Header = (props: HeaderProps) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  return (
    <div className={style.root}>
      <div
        className={style.progressBar}
        style={{ transform: `translateX(-${100 - props.progress}%)` }}
      ></div>
      <div className={style.content}>
        <span onClick={props.onReset} className={style.logo}>
          {language.toLowerCase() === AppLanguage.En ? (
            <HandlLogo light />
          ) : (
            <DbrainLogo />
          )}
        </span>
        {props.stage === AppStage.Recognize && (
          <button onClick={props.onReset} className={style.home}>
            {t('Go home')}
          </button>
        )}
      </div>
    </div>
  );
};

export default Header;
