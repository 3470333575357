export const WithHitlDocumentList = [
  'driver_license_1999_paper_back',
  'driver_license_1999_paper_front',
  'driver_license_1999_plastic_front',
  'driver_license_2011_back',
  'driver_license_2011_front',
  'driver_license_2014_back',
  'passport_main',
  'passport_registration',
  'pts_back',
  'pts_front',
  'vehicle_registration_certificate_back',
  'vehicle_registration_certificate_front',
];

export const FieldnetDocumentList = [
  'bank_card',
  'driver_license_1999_paper_back',
  'driver_license_1999_paper_front',
  'driver_license_1999_plastic_front',
  'driver_license_2011_back',
  'driver_license_2011_front',
  'driver_license_2014_back',
  'driver_license_japan',
  'inn_person',
  'kgz_passport_main',
  'kgz_passport_plastic_blue',
  'kgz_passport_plastic_red',
  'migration_card',
  'mts_rfa',
  'ndfl2',
  'ogrn',
  'passport_main',
  'passport_registration',
  'pts_back',
  'pts_front',
  'rus_work_patent',
  'snils_front',
  'tjk_passport_main',
  'uzb_passport_main',
  'vehicle_registration_certificate_back',
  'vehicle_registration_certificate_front',
];
