import React, { useRef, useState, useEffect } from 'react'
import Bbox from '../Bbox/Bbox'
import style from './BboxImage.module.css'
import { throttle } from 'lodash'
import { RecognizeStatsItem } from '../RecognizeStats/RecognizeStats'

export interface BboxImageProps {
  crop: string
  hoveredItemName: string | null
  items: RecognizeStatsItem[]
  isCoords?: boolean
}

function useForceUpdate() {
  const [, setValue] = useState(0) // integer state
  return () => setValue((value) => ++value) // update the state to force render
}

export default function BboxImage(props: BboxImageProps) {
  const imageRef = useRef<HTMLImageElement>(null)
  const forceUpdate = useForceUpdate()

  const handleImageLoad = (
    event: React.SyntheticEvent<HTMLImageElement, Event>,
  ) => {
    forceUpdate()
  }

  const handleWindowResize = throttle(() => {
    forceUpdate()
  }, 50)

  useEffect(() => {
    window.addEventListener('resize', handleWindowResize, false)
    return () => {
      window.removeEventListener('resize', handleWindowResize, false)
    }
  }, [handleWindowResize])

  const { current } = imageRef

  return (
    <div className={style.root}>
      {current && !props.isCoords &&
        props.items.map((item: RecognizeStatsItem, index: number) =>
          item.coords.map((box: number[], subIdx: number) => (
            <Bbox
              key={index + subIdx}
              isActive={props.hoveredItemName === item.name}
              coordinates={box.flat()}
              containerClientHeight={current.clientHeight}
              containerClientWidth={current.clientWidth}
              imgHeight={current.naturalHeight}
              imgWidth={current.naturalWidth}
            />
          )),
        )}
      <img
        ref={imageRef}
        src={props.crop}
        alt=""
        className={style.image}
        onLoad={handleImageLoad}
      />
    </div>
  )
}
