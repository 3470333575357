import React from 'react';
import Header from '../Header/Header';
import style from './Layout.module.css';
import { useSelector } from 'react-redux';
import { AppState } from '../../store/types';
import Footer from '../Footer/Footer';
import { AppStage } from '../../App';

export interface LayoutProps {
  children: React.ReactNode;
  stage: AppStage;
  onReset: () => void;
}

export default function Layout(props: LayoutProps) {
  const progress = useSelector((state: AppState) => state.progress);

  return (
    <div className={style.root}>
      <div className={style.header}>
        <Header
          progress={progress}
          onReset={props.onReset}
          stage={props.stage}
        />
      </div>
      {props.children}
      {props.stage === AppStage.Upload ? (
        <div className={style.footer}>
          <Footer />
        </div>
      ) : null}
    </div>
  );
}
